import {Head} from "next/document";
import React, {useEffect} from "react";
import Router from "next/router";

export const GA_TRACKING_ID = "G-M5Q087VBC7";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if ((window as any).gtag) {
    (window as any).gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({action, category, label, value}) => {
  if ((window as any).gtag) {
    (window as any).gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  }
}

const conversionSettings = {
  pro: {
    'send_to': 'AW-720440774/Os7VCIiZndwYEMabxNcC',
    'value': 49.0,
    'currency': 'USD',
  },
  essential: {
    'send_to': 'AW-720440774/VpmsCLalqdwYEMabxNcC',
    'value': 29.0,
    'currency': 'USD',
  }
}

export function triggerSignupEvent(packagePlan: string) {
  if ((window as any).gtag) {
    (window as any).gtag('event', 'conversion', conversionSettings[packagePlan]);
  }
}


export function withGA(PageComponent, {} = {}) {
  const WithGA = (props) => {

    useEffect(() => {
      const handleRouteChange = (url) => {
        pageview(url)
      }
      Router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange)
      }
    }, []);

    return (
      <>
        <Head>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${GA_TRACKING_ID}', {
                                      page_path: window.location.pathname,
                                    });
                                  `,
            }}
          />
        </Head>
        <PageComponent {...props}/>
      </>
    );
  };

  return WithGA;
}
