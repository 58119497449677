'use client'
import React, {createContext, useContext, useEffect, useState} from "react";

import * as Sentry from "@sentry/browser";

import {useRouter} from "next/router";
import moment from "moment-timezone";
import {CompanyContext} from "types";
import {initApolloClient} from "utils/apollo";
import gql from "graphql-tag";
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  signIn,
  signInWithRedirect,
  getCurrentUser,
  signOut,
  signUp as signUpLib,
  updatePassword,
  updateUserAttributes as updateUserAttributesLib, fetchUserAttributes, resetPassword as resetPasswordImpl
} from 'aws-amplify/auth';
import {Hub} from "@aws-amplify/core";
import {Cache} from 'aws-amplify/utils';
import {
  getCurrentUser as getCurrentUserServer,
  fetchAuthSession as fetchAuthSessionServer
} from 'aws-amplify/auth/server';
import {configureAmplify, runWithAmplifyServerContext} from "../../utils/amplify";

configureAmplify(null, true);


export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      timeZone
      name
      logoUrl
      currency
      platform
      shareCode
      country
      status
      packagePlan
      features
    }
  }
`;

export const AuthContext = createContext({} as any);

export function useAuth() {
  return useContext(AuthContext);
}

export async function getLoggedInUserServer(context: any) {
  if (!context) return null;
  try {
    const {req, res} = context;
    return runWithAmplifyServerContext({
      nextServerContext: {request: req, response: res},
      operation: async (contextSpec: any) => {
        try {
          const {
            username,
            userId,
            signInDetails
          } = await getCurrentUserServer(contextSpec)
          const {
            tokens: session
          } = await fetchAuthSessionServer(contextSpec);

          return {
            userId,
            username,
            signInDetails,
            session,
            token: session?.idToken?.toString(),
            attributes: session?.idToken?.payload ?? {}
          };
        } catch (e) {
          console.log(e)
          return null
        }
      }
    });
  } catch (e) {
    console.error("[ERROR] getLoggedInUserServer", e.message);
    return null;
  }
}

export async function getSessionTokenServer(context) {

  if (!context) return;
  try {
    const {req, res} = context;
    return runWithAmplifyServerContext({
      nextServerContext: {request: req, response: res},
      operation: async (contextSpec: any) => {
        const {
          tokens: session
        } = await fetchAuthSessionServer(contextSpec);
        return session?.idToken?.toString();
      }
    });
  } catch (e) {
    console.error("[ERROR] getSessionTokenServer", e.message);
    return null;
  }
}

export async function getLoggedInUserClient() {
  try {
    const {
      username,
      userId,
      signInDetails
    } = await getCurrentUser()

    const {
      tokens: session,
    } = await fetchAuthSession();

    return {
      userId,
      username,
      signInDetails,
      session,
      token: session?.idToken?.toString(),
      attributes: session?.idToken?.payload ?? {}
    };
  } catch (e) {
    console.error("[ERROR] getLoggedInUserClient", e.message);
    return null;
  }
}

export async function getSessionTokenBrowser() {
  try {
    const {
      tokens: session
    } = await fetchAuthSession();
    return session?.idToken?.toString();
  } catch (e) {
    console.error("[ERROR] getSessionTokenBrowser", e.message);
    return null;
  }
}

export async function getCompanyContext(ctx): Promise<CompanyContext | undefined> {
  const user = await getLoggedInUserServer(ctx);
  if (!user) {
    return
  }
  const apolloClient = initApolloClient(ctx);
  const company = user?.attributes ? user?.attributes["custom:company"] : undefined;

  const {
    data: {getCompany: companyObj},
  } = await apolloClient.query({
    context: {clientName: "authed"},
    query: gql(getCompany),
    variables: {
      id: user?.attributes ? user?.attributes["custom:company"] : undefined,
    },
  });

  return {
    company,
    ...companyObj,
    features: companyObj.features ? JSON.parse(companyObj.features) : {},
  };
}

export function isAccountOwner(user) {
  return user?.attributes
    ? !!user?.attributes["custom:company"]
    : !!user?.session?.idToken?.payload["custom:company"];
}


async function handleLogin(email: string, password) {
  return signIn({username: email, password}).catch((err) => {
    let message = err.message;
    if (err.code === "UserNotConfirmedException") {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      message =
        "Your email address has not been confirmed.  Please check your " +
        "inbox and follow the instructions in the welcome email.";

      console.log(message);
    } else if (err.code === "PasswordResetRequiredException") {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      console.log(err);
    } else if (err.code === "NotAuthorizedException") {
      // The error happens when the incorrect password is provided
      console.log(err);
    } else if (err.code === "UserNotFoundException") {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      console.log(err);
    } else {
      console.log(err);
    }
    throw err;
  });
}

export const AuthProvider = ({
                               children,
                               viewerCountry,
                               platform = null,
                               timeZone = moment.tz.guess(),
                               status,
                               packagePlan,
                               currency,
                               country,
                             }) => {
  const router = useRouter();
  const [user, setUserObj] = useState<any>(null);
  const [company, setComany] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  function setUser(user) {
    user.attributes = user?.session?.idToken?.payload;
    setUserObj(user);
  }

  useEffect(() => {
    if (user && user.attributes) {
      const attributes =
        user.attributes;
      setComany(attributes["custom:company"]);
      setCompanyData({
        id: attributes["custom:company"],
        status,
        packagePlan,
        timeZone,
        platform,
        currency,
        country,
      });
      Sentry.setUser({
        email: attributes?.email,
        company: attributes["custom:company"],
      });
    }
  }, [user]);

  useEffect(() => {
    initialiseUser().catch((e) => console.log("auth init", e));
    Hub.listen("auth", (payloadData) => {
      const {
        payload: {event},
      } = payloadData;
      console.log(payloadData);
      switch (event) {
        case "signedIn":
          // case "signInWithRedirect":
          setTimeout(() => {
            initialiseUser(true)
              .then((u) => {
                console.log(`cognitoHostedUI`, u);
                if (u?.attributes["custom:company"]) {
                  router.push("/admin/");
                } else {
                  router.push("/auth/social-signup/");
                }
              })
              .catch((e) => {
                console.log(`cognitoHostedUI error`, e);
                router.push("/login");
              });
          }, 1000);
          break;
        case "customOAuthState":

          break;
        case "signInWithRedirect_failure":

          break;
      }
    });
  }, []);

  async function initialiseUser(bypassCache = false) {
    return getLoggedInUserClient()
      .then((user) => {
        if (user) {
          setUser(user);
          return user;
        }
      });
  }

  const login = (email, password) => {
    setLoading(true);
    return handleLogin(email, password)
      .then((user) => {
        setUser(user);
        return user;
      })
      .finally(() => setLoading(false));
  };

  const completeNewPassword = async (
    user,
    newPassword,
    attributes,
    clientMetadata,
  ) => {
    setLoading(true);
    const {
      isSignedIn,
      nextStep
    } = await confirmSignIn({challengeResponse: newPassword});
    console.log(isSignedIn, nextStep)
    initialiseUser(true)
      .then((user) => {
        setUser(user);
        return user;
      })
      .finally(() => setLoading(false));
  };

  const resetPassword = (username) => {
    setLoading(true);
    return resetPasswordImpl({
      username
    }).finally(() => setLoading(false));
  };
  const newPasswordSubmit = (username, confirmationCode, newPassword) => {
    setLoading(true);
    return confirmResetPassword({
      username,
      newPassword,
      confirmationCode,
    }).finally(() =>
      setLoading(false),
    );
  };

  const federatedSignIn = ({provider, customState}) => {
    setLoading(true);
    return signInWithRedirect({provider, customState}).finally(() =>
      setLoading(false),
    );
  };

  async function signUp({
                          username,
                          password,
                          firstName,
                          lastName,
                          attributes,
                        }) {
    Cache.setItem("tmpsignup", {pw: password});
    setLoading(true);
    const {
      isSignUpComplete,
      userId,
      nextStep
    } = await signUpLib({
      username,
      password,
      options: {
        userAttributes: {
          email: username,
          given_name: firstName,
          family_name: lastName,
          "custom:signup": JSON.stringify(attributes),
        },
      }
    }).finally(() => setLoading(false))

    console.log(isSignUpComplete,
      userId,
      nextStep)
    return;
  }

  async function logout() {
    setLoading(true);
    return signOut()
      .then(() => {
        Sentry.configureScope((scope) => scope.setUser(null));
      })
      .finally(() => setLoading(false));
  }

  async function changePassword(oldPassword, newPassword) {
    setLoading(true);
    return updatePassword({oldPassword, newPassword}).then((data) => console.log(data))
      .finally(() => setLoading(false));
  }

  async function updateUserAttributes(attributes) {
    setLoading(true);
    return updateUserAttributesLib(attributes).then((data) => console.log(data))
      .then(() => initialiseUser(true))
      .finally(() => setLoading(false));
  }

  return (
    <AuthContext.Provider
      value={{
        platform, // deprecated, use companyData
        company,
        companyData,
        login,
        logout,
        signUp,
        user,
        isAuthenticated: !!user,
        federatedSignIn,
        loading,
        resetPassword,
        newPasswordSubmit,
        initialiseUser,
        viewerCountry,
        timeZone, // deprecated, use companyData
        changePassword,
        updateUserAttributes,
        completeNewPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
