import {getAmplifyConfig} from "./config";
import {Amplify, type ResourcesConfig} from 'aws-amplify';
import {CookieStorage} from 'aws-amplify/utils';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';
import {createServerRunner} from '@aws-amplify/adapter-nextjs';

function getAuthConfig(): ResourcesConfig['Auth'] {
  const awsconfig: any = getAmplifyConfig();
  const config: ResourcesConfig['Auth'] = {
    Cognito: {
      userPoolId: awsconfig.aws_user_pools_id,
      userPoolClientId: awsconfig.aws_user_pools_web_client_id,
      identityPoolId: awsconfig.aws_cognito_identity_pool_id,
      loginWith: {
        oauth: {
          domain: awsconfig.oauth.domain,
          providers: awsconfig.oauth.providers,
          scopes: awsconfig.oauth.scopes,
          redirectSignIn: awsconfig.oauth.redirectSignIn,
          responseType: awsconfig.oauth.responseType,
          redirectSignOut: awsconfig.oauth.redirectSignOut,
        },
        email: true
      },
      allowGuestAccess: false,
      passwordFormat: {
        minLength: 8,
        requireNumbers: true,
        requireLowercase: true,
        requireUppercase: true
      },
      signUpVerificationMethod: "code",
      userAttributes: {},
    }
  };
  return config;
}

export function configureAmplify(ctx, ssr,) {
  Amplify.configure({
    Auth: getAuthConfig(),
    Storage: {
      S3: {
        bucket: getAmplifyConfig()?.aws_user_files_s3_bucket,
        region: 'eu-west-1'
      }
    }
  }, {ssr: true});

  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    path: '/',
    expires: 365,
    secure: !(process.env.NODE_ENV === 'development'),
  }));
}


export const {runWithAmplifyServerContext} = createServerRunner({
  config: {Auth: getAuthConfig()}
});
