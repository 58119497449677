import React from 'react';
import {ApolloProvider} from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import {createAuthLink} from 'aws-appsync-auth-link';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import fetch from 'isomorphic-unfetch';
import {ApolloLink} from 'apollo-link';
import {getAmplifyConfig} from "./config";
import {fetchAuthSession} from "aws-amplify/auth/server";
import {getSessionTokenBrowser, getSessionTokenServer} from "../contexts/auth/auth.provider";

let apolloClient: any = null;


const url = (getAmplifyConfig() as any).aws_appsync_graphqlEndpoint;
const region = (getAmplifyConfig() as any).aws_appsync_region;

const auth: any = {
    type: 'AWS_IAM',
    credentials: () => ({
        accessKeyId: process.env.NEXT_PUBLIC_ACCESS_KEY_ID, // public-graphql
        secretAccessKey: process.env.NEXT_PUBLIC_SECRET_ACCESS_KEY,
    })
};

const httpLink = createHttpLink({uri: url, fetch});

const unauthedLink = ApolloLink.from([
    createAuthLink({url, region, auth}),
    createSubscriptionHandshakeLink(url, httpLink),
]);


export function getAuthedLink(jwtToken) {

    return ApolloLink.from([
        createAuthLink({
            url,
            region,
            auth: {
                type: 'AMAZON_COGNITO_USER_POOLS',
                jwtToken,
            },
        }),
        createSubscriptionHandshakeLink(url, httpLink),
    ]);

}

export function withApollo(PageComponent, {} = {}) {
    const WithApollo = ({...pageProps}) => {
        const {apolloClient, apolloState} = pageProps;

        const client = apolloClient || initApolloClient(apolloState);
        return (
            <ApolloProvider client={client}>
                <PageComponent {...pageProps} />
            </ApolloProvider>
        );
    };

    // Set the correct displayName in development
    if (process.env.NODE_ENV !== 'production') {
        const displayName =
            PageComponent.displayName || PageComponent.name || 'Component';

        if (displayName === 'App') {
            console.warn('This withApollo HOC only works with PageComponents.');
        }

        WithApollo.displayName = `withApollo(${displayName})`;
    }

    return WithApollo;
}



/*
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
export function initApolloClient(context) {

    // Make sure to create a new client for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (typeof window === 'undefined') {
        return createApolloClientServer(context);
    }
    // Reuse client on the client-side
    if (!apolloClient) {
        apolloClient = createApolloClientBrowser();
    }
    return apolloClient;
}

function createApolloClientServer(context) {

    const getJwtToken = async () => getSessionTokenServer(context);


    // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
    // Check https://www.loudnoises.us/next-js-two-apollo-clients-two-graphql-data-sources-the-easy-way/ for splitting links
    return new ApolloClient({
        ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
        link: ApolloLink.split(
            operation => operation.getContext().clientName === 'authed', // Routes the query to the proper client
            getAuthedLink(getJwtToken),
            unauthedLink
        ),
        cache: new InMemoryCache().restore({}),
    });
}

function createApolloClientBrowser() {

    const getJwtToken = async () => getSessionTokenBrowser();


    // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
    // Check https://www.loudnoises.us/next-js-two-apollo-clients-two-graphql-data-sources-the-easy-way/ for splitting links
    return new ApolloClient({
        ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
        link: ApolloLink.split(
            operation => operation.getContext().clientName === 'authed', // Routes the query to the proper client
            getAuthedLink(getJwtToken),
            unauthedLink
        ),
        cache: new InMemoryCache().restore({}),
    });
}


const backendLink = ({key, secret}) => ApolloLink.from([
    createAuthLink({
        url,
        region,
        auth: {
            type: 'AWS_IAM',
            credentials: () => ({
                accessKeyId: key,
                secretAccessKey: secret,
            })
        }
    }),
    createSubscriptionHandshakeLink(url, createHttpLink({uri: url, fetch})),
]);


export function createBackendApolloClient({key, secret}) {
    return new ApolloClient({
        ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
        link: backendLink({key, secret}),
        cache: new InMemoryCache().restore({}),
    });
}


