import {Logo} from "components/logos";
import React from "react";

const navigation = [
    {name: 'Home', href: 'https://www.checkoutjoy.com/'},
    {name: 'Pricing', href: 'https://www.checkoutjoy.com/pricing/'},
    {name: 'Docs', href: 'https://help.checkoutjoy.com/'},
]

export default function Header() {
    return (
        <header className="bg-indigo-600">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
                <div
                    className="w-full py-3 flex items-center justify-between border-b border-indigo-500 lg:border-none">
                    <div className="flex items-center">
                        <a href="https://www.checkoutjoy.com/">
                            <Logo className="hidden h-6 w-auto md:block sm:h-8"/>
                            <img src={"/images/cart-square-white.png"} alt="logo" className="h-10 w-auto sm:h-8 md:hidden"/>
                        </a>
                        <div className="hidden ml-10 space-x-8 lg:block">
                            {navigation.map((link) => (
                                <a key={link.name} href={link.href}
                                   className="text-base font-medium text-white hover:text-indigo-50">
                                    {link.name}
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className="ml-10 space-x-4">
                        <a
                            href="/login/"
                            className="inline-block bg-indigo-500 py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-opacity-75"
                        >
                            Sign in
                        </a>
                        <a
                            href="/signup/"
                            className="inline-block bg-white py-2 px-4 border border-transparent rounded-md text-base font-medium text-indigo-600 hover:bg-indigo-50"
                        >
                            Start free trial
                        </a>
                    </div>
                </div>
                <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
                    {navigation.map((link) => (
                        <a key={link.name} href={link.href}
                           className="text-base font-medium text-white hover:text-indigo-50">
                            {link.name}
                        </a>
                    ))}
                </div>
            </nav>
        </header>
    )
}
