export function getAmplifyConfig() {
  switch (process.env.STAGE) {
    case 'test':
      return {
        "aws_project_region": "eu-west-1",
        "aws_cognito_identity_pool_id": "eu-west-1:87604165-83e5-417d-9223-2f32523ba7de",
        "aws_cognito_region": "eu-west-1",
        "aws_user_pools_id": "eu-west-1_lQkw63aDP",
        "aws_user_pools_web_client_id": "5d2598juo37cedit5d0t38c5cq",
        "oauth": {
          "domain": "kpayments032fc9e0-032fc9e0-test.auth.eu-west-1.amazoncognito.com",
          "redirectSignIn": [process.env.NODE_ENV === "development" ? "http://localhost:4002/auth/social/" : `https://www.checkoutjoy-sb.com/auth/social/`],
          "redirectSignOut": ["https://www.checkoutjoy.com/"],
          "responseType": "code",
          "clientId": "5d2598juo37cedit5d0t38c5cq",
          "scopes": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
          ],
        },
        "federationTarget": "COGNITO_USER_POOLS",
        "aws_appsync_graphqlEndpoint": "https://sfxyoqdc3jhmjl4iuc7yep5plq.appsync-api.eu-west-1.amazonaws.com/graphql",
        "aws_appsync_region": "eu-west-1",
        "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
        "aws_user_files_s3_bucket": "cjstorage2000151754-test",
        "aws_user_files_s3_bucket_region": "eu-west-1"
      };
    case 'prod':
      return {
        "aws_project_region": "eu-west-1",
        "aws_cognito_identity_pool_id": "eu-west-1:b2de388e-89ce-4d64-b8cc-f8e0169bec75",
        "aws_cognito_region": "eu-west-1",
        "aws_user_pools_id": "eu-west-1_IyZjriroS",
        "aws_user_pools_web_client_id": "7bnsvtarh48um5ddr0ipkdhlj",
        "oauth": {
          "domain": "auth.checkoutjoy.com",
          "scopes": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
          ],
          "redirectSignIn": [process.env.NODE_ENV === "development" ? "http://localhost:4002/auth/social/" : "https://www.checkoutjoy.com/auth/social/"],
          "redirectSignOut": ["https://www.checkoutjoy.com/"],
          "responseType": "code",
          "clientId": "7bnsvtarh48um5ddr0ipkdhlj",
        },
        "federationTarget": "COGNITO_USER_POOLS",
        "aws_appsync_graphqlEndpoint": "https://mbwusdk77zacba4w7fhck3uv3i.appsync-api.eu-west-1.amazonaws.com/graphql",
        "aws_appsync_region": "eu-west-1",
        "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
        "aws_cloud_logic_custom": [
          {
            "name": "AdminQueries",
            "endpoint": "https://3zl64901he.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
          }
        ],
        "aws_mobile_analytics_app_id": "6b856af4110541fbaf57446197218f2b",
        "aws_mobile_analytics_app_region": "eu-west-1",
        "aws_user_files_s3_bucket": "cjstorage200084125-prod",
        "aws_user_files_s3_bucket_region": "eu-west-1",
      };
  }

}
