import React, {createContext, useContext, useState} from 'react';
import {Company} from "../API";
import {getLoggedInUserClient, getLoggedInUserServer} from "./auth/auth.provider";

type CompanyContextType = Company & {
    enableFeature: (id: string, data?: any) => Promise<any>,
    disableFeature: (id: string) => Promise<any>,
    loading: boolean,
    features: any
};
export const CompanyContext = createContext<CompanyContextType>({} as CompanyContextType);

export function useCompany() {
    return useContext(CompanyContext);
}

export const CompanyProvider = ({children, company}) => {
    const [loading, setLoading] = useState(false);
    const [features, setFeatures] = useState(company?.features ?? {});


    async function enableFeature(featureId, data?) {
        setLoading(true);
        const user = await getLoggedInUserClient();
        const token = user?.token;
        const newFeatures = await fetch(`${process.env.NEXT_PUBLIC_CORE_SVC_URL}/features/enable`, {
            method: 'post',
            headers: {
                Authorization: token ?? ''
            },
            body: JSON.stringify({
                id: featureId,
                data
            })
        })
            .then(async (rsp) => {
                const body = await rsp.json();
                if (rsp.status > 299) {
                    throw new Error(body.message);
                }
                return body
            })
            .then(rsp => {
                console.log(rsp);
                setFeatures(rsp)
            })
            .finally(() => setLoading(false))

        return newFeatures;
    }

    async function disableFeature(featureId) {
        const user = await getLoggedInUserClient();
        const token = user?.token;
        return fetch(`${process.env.NEXT_PUBLIC_CORE_SVC_URL}/features/disable`, {
            method: 'post',
            headers: {
                Authorization: token ?? ''
            },
            body: JSON.stringify({
                id: featureId
            })
        })
            .then(async (rsp) => {
                const body = await rsp.json();
                if (rsp.status > 299) {
                    throw new Error(body.message);
                }
                return body
            })
            .then((newFeatures) => {
                setFeatures(newFeatures);
                return newFeatures
            })
            .finally(() => setLoading(false))
    }

    return (
        <CompanyContext.Provider value={{
            ...company,
            features,
            loading,
            enableFeature,
            disableFeature
        }}>
            {children}
        </CompanyContext.Provider>
    );
};
