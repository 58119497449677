import React from "react";
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        // @ts-ignore
        if (this.state.errorInfo) {
            // Error path
            return (
                <>
                    <div
                        className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16">
                        <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                            <div className="relative">
                                <div className="absolute">
                                    <div className="">
                                        <h1 className="my-2 text-gray-800 font-bold text-2xl">
                                            Looks like something bad happened here :(
                                        </h1>
                                        <p className="my-2 text-gray-800">Sorry about that! Our team has been notified.</p>
                                        <button
                                            onClick={() => window.location.href = "/admin/"}
                                            className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">
                                            Go to my dashboard
                                        </button>
                                        <button
                                            onClick={() => Sentry.showReportDialog()}
                                            className="sm:w-full lg:w-auto my-2 border rounded md py-4 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50">
                                            Report feedback
                                        </button>
                                        <div>
                                            <h2>Something went wrong.</h2>
                                            <details style={{whiteSpace: "pre-wrap"}}>
                                                {(this.state as any).error && (this.state as any).error.toString()}
                                                <br/>
                                                {(this.state as any).errorInfo.componentStack}
                                            </details>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src="https://i.ibb.co/G9DC8S0/404-2.png"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src="https://i.ibb.co/ck1SGFJ/Group.png"/>
                        </div>
                    </div>
                </>

            );
        }
        // Normally, just render children
        return (this.props as any).children;
    }
}
