import App from 'next/app'
import '../assets/css/tailwind.css';
import {AuthProvider, getCompanyContext} from "../contexts/auth/auth.provider";

import '../assets/css/blockui.css';
import '../assets/css/peachForm.css';
import Head from "next/head";
import React from "react";
import Router from "next/router";
import ProgressBar from '@badrap/bar-of-progress'
import 'balloon-css';
import * as Sentry from '@sentry/node';
import * as gtag from '../utils/gtag'
import {configureAmplify} from "utils/amplify";
import {Toaster} from "react-hot-toast";
import BlogContainer from "../modules/blog/Container";
import SimpleFooter from "../components/Layouts/SimpleFooter";
import Header from "../modules/blog/components/Header";
import {CompanyProvider} from "../contexts/Company.provider";
import ErrorBoundary from "../components/ErrorBoundary";
import {Inter} from "next/font/google";
import { classNames } from "../utils/ui";

const inter = Inter({subsets: ['latin']})


if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const sentryParams = {
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        // replaysSessionSampleRate: 0.1,
        //
        // // If the entire session is not sampled, use the below sample rate to sample
        // // sessions when an error occurs.
        // replaysOnErrorSampleRate: 1.0,
        //
        // integrations: [
        //     new Sentry.Replay({
        //         // Additional SDK configuration goes in here, for example:
        //         maskAllText: true,
        //         blockAllMedia: true,
        //     }),
        // ],
    };
    Sentry.init(sentryParams);
}

const progress = new ProgressBar({
    size: 2,
    color: '#5a67d8',
    className: 'bar-of-progress',
    delay: 100,
});
Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

// Notice how we track pageview when route is changed
Router.events.on('routeChangeComplete', (url) => gtag.pageview(url))

function isProcessorPage(pathname) {
    return pathname?.includes('r/buttons')
        || pathname === '/checkout/[id]'
        || pathname?.includes('r/kajabi')
        || pathname?.includes('r/processor');
}

function isQuadernoAuthPage(pathname) {
    return pathname?.includes('quaderno/auth');
}

function isAdminPage(pathname) {
    return pathname?.includes('/admin');
}

configureAmplify(null, true);

function MyApp({Component, pageProps, err, country, pathname, company}) {

    const isProcessor = isProcessorPage(pathname);
    const isQuadernoAuth = isQuadernoAuthPage(pathname);

    /*
     * Amplify should not be initialised if this is the Quaderno callback since it will clash with the
     * OAuth authentication of Quaderno
     */
    if (!isQuadernoAuth) {
        try {
            // configureAmplify(null, true);
        } catch (e) {
            console.log(e)
        }
    }

    return <>
        {
            process.env.NODE_ENV !== "development" && process.env.NEXT_PUBLIC_STAGE === 'test' && !isProcessor &&
            <div className="relative bg-red-600">
                <div className="mx-auto py-1 px-3 sm:px-6 lg:px-8">
                    <div className="pr-16 sm:text-center sm:px-16">
                        <p className="font-medium text-white">
                            This is the sandbox environment - click <a href="https://www.checkoutjoy.com/">here</a> for
                            the
                            Live environment.
                        </p>
                    </div>
                </div>
            </div>
        }
        {
            !isProcessor &&
            <Head>
                <link rel="shortcut icon" href="/favicon.png"/>
                <title>CheckoutJoy</title>
                <meta property="og:url" content={`https://www.checkoutjoy.com${pathname}`}/>
                <meta property="og:site_name" content={"CheckoutJoy"}/>
                <script src="https://cdn.checkoutjoy.com/cj-affiliate.js"/>
            </Head>
        }

        {
            (isProcessor || isQuadernoAuth) ? <Component {...pageProps} err={err}/>
                :
                <ErrorBoundary>
                    <div className={classNames(inter.className, "cj-content h-full")}>
                        <Toaster containerClassName="toastie-container"
                                 toastOptions={{
                                     className: 'toastie',
                                     style: {
                                         borderRadius: '10px',
                                         background: '#333',
                                         color: '#fff',
                                     },
                                 }}
                        />


                        <AuthProvider
                            platform={company?.platform}
                            viewerCountry={country}
                            currency={company?.currency}
                            timeZone={company?.timeZone}
                            status={company?.status}
                            packagePlan={company?.packagePlan}
                        >
                            <CompanyProvider company={company}>
                                {
                                    pathname?.includes('blog') ?
                                        <div className="antialiased">

                                            <>
                                                <Header/>
                                                <BlogContainer>
                                                    <Component {...pageProps} err={err}/>
                                                </BlogContainer>
                                            </>
                                            <SimpleFooter/>
                                        </div>
                                        :
                                        <Component {...pageProps} err={err}/>
                                }
                            </CompanyProvider>
                        </AuthProvider>
                    </div>
                </ErrorBoundary>
        }

    </>
}


MyApp.getInitialProps = async (appContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const country = appContext.ctx.req?.headers['cloudfront-viewer-country'] || 'US';
    console.log(country, appContext.router?.pathname);

    const props = {};
    if (isAdminPage(appContext.router?.pathname)) {
        // get/set the companyContext
        const companyContext = await getCompanyContext(appContext.ctx);
        Object.assign(props, companyContext ?? {});
    }


    return {
        ...appProps,
        country,
        pathname: appContext.router?.pathname,
        hostname: appContext.ctx.req?.headers.host,
        company: props,
    };
}

// Standard React extension point for capturing errors.
MyApp.componentDidCatch = (error, errorInfo) => {
    console.log(`FRONTEND ERROR`, error, errorInfo);
}

// export default withGA("UA-175908263-1", Router)(MyApp);
export default MyApp;
